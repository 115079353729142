<template>
  <div class="container" id="container">

    <div class="van-notice-bar-box">
      <van-notice-bar left-icon="volume-o" :scrollable="false">
        <van-swipe vertical class="notice-swipe" :autoplay="3000" :show-indicators="false">
          <van-swipe-item v-for="(item, i) in messageList" :key="i" @click="goMessageDetail(item)">
            <p class="notice-swipe-content">{{ item.content }}</p>(点击查看详情)
          </van-swipe-item>
        </van-swipe>
      </van-notice-bar>
    </div>



    <div class="top-banner">
      <div class="banner-img">
        <img v-if="isMobile" class="banner" src="../../assets/images/jobs/banner-m.png" alt="" />
        <img v-else class="banner" src="../../assets/images/jobs/banner-pc.png" alt="" />
      </div>
    </div>
    <div class="main-page">

      <template v-if="noticeList.length > 0">
        <div class="company-info">
          <div class="title">
            <p class="name">公告</p>
          </div>
        </div>
        <div class="notice-list">
          <van-collapse v-model="activeNames">
            <van-collapse-item v-for="(notice, i) in noticeList" :key="i" :title="notice.title" :name="i">
              <div v-html="notice.content"></div>
            </van-collapse-item>
          </van-collapse>
        </div>
      </template>

      <div v-if="mysignList.length > 0" class="company-info">
        <div class="title">
          <p class="name">我的报名</p>
        </div>
      </div>

      <template v-if="mysignList.length > 0">
        <div class="job-list job-result">
          <div v-if="!isLogin" class="btn confirm" @click="goLogin">
            请登录查看
          </div>
          <div v-else>
            <p v-if="mysignList.length === 0">暂无报名记录</p>
            <div v-else>
              <div class="my-sign" v-for="item in mysignList" :key="item.id">
                <p style="width:100%;font-weight: bold;">职位名称：{{ item.job_title }}</p>
                <p>姓名：{{ item.realname }}</p>
                <p>电话：{{ item.mobile }}</p>
                <p>状态：<span style="color: #e95944;font-weight: bold;">{{ item.item_show_status }}</span> <span
                    v-if="item.item_show_msg"
                    style="color: #e95944;font-weight: bold;font-size: 11px;display: block;">{{ item.item_show_msg }}</span></p>
                <p v-if="item.need_pay == 1">支付状态：<span style="color: #e95944;font-weight: bold;">{{ item.pay_status2 ==
        0 ? '未支付' : '已支付' }}</span></p>
                <p v-if="item.status_msg">反馈意见：{{ item.status_msg }}</p>
                <div class="btn-box">
                  <a class="btn confirm btn-download"
                    v-if="item.item_show_operation == 'edit'"
                    href="javascript:;" @click="editSign(item)">修改</a>
                  <a class="btn confirm btn-download"
                    v-if="item.need_pay == 1 && item.status == 1 && item.pay_status2 == 0" href="javascript:;"
                    @click="goPayfinal(item)">支付</a>
                  <a class="btn confirm btn-download"
                    v-if="item.item_show_operation == 'interview'"
                    href="javascript:;" @click="toReservation(item)">预约</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>


      <!-- 热门职位 -->

      <template>
        <div class="company-info">
          <div class="title">
            <p class="name">热门职位</p>
            <a @click="toJobList" class="title-more" href="javascript:;">更多职位 <van-icon name="arrow" /> </a>
          </div>
        </div>
        <div class="job-list">
          <van-empty v-show="showEmpty"> 暂无岗位 </van-empty>
          <van-list v-model="loading" :finished="finished" :error.sync="error" :error-text="errorText"
            loading-text="加载中..." :finished-text="finishedText" @load="getJobList">
            <div v-for="(job, i) in jobList" :key="i" class="job-detail">
              <div style="position: relative; flex:1;">
                <div class="top-info">
                  <div class="title ellipsis line2">{{ job.title }}</div>
                </div>
                <div class="labels">
                  <div class="label">
                    <icon-svg class="icon-label" name="location"></icon-svg>
                    <span class="ellipsis line1 work-city"> {{ job.city }} </span>
                  </div>
                  <span class="bar"></span>
                  <div class="label">
                    <icon-svg class="icon-label" name="education"></icon-svg>
                    <span class="ellipsis line1"> {{ job.education }}</span>
                  </div>
                  <span class="bar"></span>
                  <div class="label">
                    <icon-svg class="icon-label" name="category"></icon-svg>
                    <span class="ellipsis line1"> {{ job.people }} </span>
                  </div>
                </div>
              </div>

              <div class="btn confirm" @click="goDetail(job)">
                详情
              </div>
            </div>
          </van-list>
        </div>
      </template>


      <!-- 热门活动 -->

      <template>
        <div class="company-info">
          <div class="title">
            <p class="name">热门活动</p>
            <a @click="toEventList" class="title-more" href="javascript:;">更多活动 <van-icon name="arrow" /> </a>
          </div>
        </div>
        <div class="job-list">
          <van-empty v-show="showEmptyEvent"> 暂无活动 </van-empty>
          <van-list v-model="loadingEvent" :finished="finishedEvent" :error.sync="errorEvent" :error-text="errorText"
            loading-text="加载中..." :finished-text="finishedTextEvent" @load="getEventList">
            <div v-for="(event, i) in eventList" :key="i" class="job-detail">
              <img class="event-cover" :src="event.cover_url" alt="">
              <div style="position: relative; flex:1;">
                <div class="top-info">
                  <div class="title ellipsis line2">{{ event.title }}</div>
                </div>
                <div class="labels">
                  <div class="label event-label">
                    <span class="work-city"> {{ (event.start_time * 1000) | formatDate_ }} - {{ (event.end_time *
        1000) | formatDate_ }} </span>
                  </div>
                </div>
              </div>

              <div class="btn confirm" @click="goEventDetail(event)">
                详情
              </div>

            </div>
          </van-list>
        </div>
      </template>



    </div>
  </div>
</template>

<script>
import cookies from "@/libs/util.cookies";

export default {
  name: "JobList",
  components: {},
  data() {
    return {
      share: false,
      errorText: "网络开小差了，点击重新加载",

      noticeList: [],
      activeNames: [],
      mysignList: [],

      jobList: [],
      error: false,
      loading: false,
      finished: false,

      eventList: [],
      errorEvent: false,
      loadingEvent: false,
      finishedEvent: false,
      messageList: []

    };
  },
  computed: {
    showEmpty() {
      return !this.error && !this.loading && this.jobList.length === 0;
    },
    showEmptyEvent() {
      return !this.error && !this.loading && this.jobList.length === 0;
    },
    finishedText() {
      return this.jobList.length === 0 ? "" : "";
    },
    finishedTextEvent() {
      return this.eventList.length === 0 ? "" : "";
    },
    isMobile() {
      return document.body.clientWidth < 992;
    },
    isLogin() {
      return cookies.get(process.env.VUE_APP_TOKEN);
    },
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler: function (newPath, oldPath) {
        if (newPath !== oldPath) {
          const container = document.getElementById("container");
          if (container) {
            container.scrollTop = 0;
          }
          this.refreshJobList();
        }
      },
    },
  },
  created() { },
  mounted() {
    this.getNoticeList();
    if (this.isLogin) {
      this.getMysignList();
      this.getMessageList()
    }
  },
  methods: {
    statusLabel(value) {
      const statusOptions = [
        {
          value: "0",
          label: "待审核",
        },
        {
          value: "1",
          label: "合格",
        },
        {
          value: "2",
          label: "不合格",
        },
        {
          value: "3",
          label: "待定",
        },
        {
          value: "4",
          label: "重新编辑",
        },
        {
          value: "10",
          label: "已预约面试",
        },
        {
          value: "11",
          label: "已面试",
        },
        {
          value: "12",
          label: "已面试",
        },
        {
          value: "13",
          label: "已面试",
        },
        {
          value: "20",
          label: "已赴任",
        },
        {
          value: "21",
          label: "不能赴任",
        },
        {
          value: "22",
          label: "离任",
        },
        {
          value: "23",
          label: "退出本项目",
        }
      ];

      const label = statusOptions.find((item) => item.value === value);

      if (label) {
        return label.label;
      } else {
        return "待审核";
      }
    },
    editSign(item) {
      this.$router.push({
        name: "Resume",
        query: {
          id: item.id,
          job_id: item.job_id,
        },
      });
    },
    // 我的报名列表
    getMysignList() {
      this.$api
        .GET_MYSIGN_LIST({
          limit: 100,
          offset: 0,
        })
        .then((res) => {
          this.mysignList = res || [];
        });
    },

    // 获取公告列表
    getNoticeList() {
      this.$api
        .GET_NOTICE_LIST({
          limit: 3,
          offset: 0,
        })
        .then((res) => {
          this.noticeList = res || [];
        });
    },
    toJobList() {
      this.$router.push({
        name: "JobList",
      });
    },

    toEventList() {
      this.$router.push({
        name: "EventList",
      });
    },
    // 重新获取列表数据
    refreshJobList() {
      // 清空列表数据
      this.jobList = [];
      this.finished = false;
      this.getJobList();

      this.eventList = [];
      this.finishedEvent = false;
      this.getEventList();
    },

    // 获取职位列表
    getJobList() {
      this.loading = true;
      const params = {
        offset: 0,
        limit: 5,
      };
      this.$api
        .GET_POSITION_LIST(params)
        .then((res) => {
          this.jobList = res;
          this.finished = true;
          this.error = false;
        })
        .catch(() => {
          this.error = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    // 获取活动列表
    getEventList() {
      this.loading = true;
      const params = {
        offset: 0,
        limit: 5,
      };
      this.$api
        .GET_EVENT_ALL(params)
        .then((res) => {
          this.eventList = res;
          this.finishedEvent = true;
          this.errorEvent = false;
        })
        .catch(() => {
          this.errorEvent = true;
        })
        .finally(() => {
          this.loadingEvent = false;
        });
    },

    // 职位详情
    goDetail(job) {
      this.$router.push({
        name: "JobDetail",
        query: {
          job_id: job.id,
        },
      });
    },

    getMessageList() {
      this.$api.GET_MESSAGE_ALL({
        offset: 0,
        limit: 5,
      }).then(res => {
        this.messageList = res
      })
    },

    toReservation(item) {
      this.$router.push({
        name: "MineApplicationReservation",
        query: {
          job_id: item.job_id,
        },
      });
    },

    goEventDetail(event) {
      this.$router.push({
        name: "EventDetail",
        query: {
          event_id: event.id,
        },
      });
    },

    goLogin() {
      this.$router.push({
        name: "Login",
      });
    },

    goPay(item) {
      this.$router.push({
        name: "Pay",
        query: {
          job_id: item.job_id
        }
      });
    },
    goPayfinal(item) {
      this.$router.push({
        name: "Payfinal",
        query: {
          job_id: item.job_id
        }
      });
    },
    goMessageDetail(event) {
      this.$router.push({
        name: "MessageDetail",
        query: {
          id: event.id,
        },
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: calc(100vh - 50px);
  overflow-y: scroll;
  background: white;
  padding-bottom: 75px;
}

.top-banner {
  .banner-video {
    width: 100%;
    z-index: 10;
    overflow: hidden;

    .video {
      overflow: hidden;
      // border-radius: 15px 15px 0 0;
    }
  }

  .banner-img img {
    width: 100%;
  }
}

.job-list {
  padding-bottom: 25px;

  .event-cover {
    display: block;
    width: calc(100% - 40px);
    margin: 0 20px;
  }



  ::v-deep .van-empty__image {
    width: 216px;
  }

  ::v-deep .van-empty__image img {
    width: 216px;
    height: 150px;
  }

  ::v-deep .van-empty__bottom {
    color: #999999;
    font-size: 14px;
  }

  ::v-deep .van-list__finished-text {
    color: #cccccc;
  }
}

.company-info {
  padding: 15px 19px;

  .top {
    display: flex;
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .name {
      position: relative;
      margin: 0;
      font-size: 18px;
      font-weight: bold;
      padding-left: 8px;

      &::before {
        display: block;
        content: "";
        width: 3px;
        height: 18px;
        background: #e95944;
        position: absolute;
        top: 4px;
        left: 0;
      }
    }

    span {
      display: flex;
      line-height: 24px;
    }
  }

  .title-more {
    display: flex;
    align-items: center;
    color: #333;
  }
}

::v-deep .van-popup__close-icon--top-right {
  top: 24px;
}

.btn {
  height: 38px;
  width: 100px;
  margin: 5px 20px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  border-radius: 25px;
  border: 1px solid #eaedf0;
  background: linear-gradient(90deg, #e95944 0%, #e95944 100%);
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
    opacity: .5;
  }
}

.confirm {
  color: #ffffff;
}

.job-result {
  .btn {
    margin: 0 auto;
    cursor: pointer;
  }
}

.detail-join {
  .btn {
    margin: 10px auto;
    cursor: pointer;
  }
}

::v-deep {
  .notice-list {
    .van-cell__title {
      text-align: left;
      font-size: 14px;
    }
  }

  .van-notice-bar__left-icon,
  .van-notice-bar__right-icon {
    min-width: 16px;
    font-size: 14px;
  }
}

.my-sign {
  margin: 0 20px;
  display: flex;
  align-items: center;
  padding: 10px;
  flex-wrap: wrap;
  background: #f3f3f3;
  border-radius: 20px;
  font-size: 15px;
  position: relative;
  margin-bottom: 10px;

  p {
    margin: 0 5px;
    width: 100%;
    text-align: left;
    margin-bottom: 5px;
  }

  .btn-box {
    display: flex;
    position: absolute;
    top: 5px;
    right: 5px;
  }

  .btn {
    width: 80px;
    height: 30px;
    margin: 0;
  }
}

.detail-join-fixed {
  width: 100%;
  background: #fff;
  position: fixed;
  bottom: 0px;
  left: 0px;
  text-align: center !important;
  z-index: 999;
  margin-bottom: 0 !important;
}

.btn-download {
  width: 120px;
  height: 40px;
  margin-top: 15px;
  color: #e95944;
  background: #ddd;
}


.notice-swipe {
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  margin-left: 10px;
}

::v-deep .van-notice-bar__wrap,
::v-deep .van-notice-bar__content.van-ellipsis {
  width: 100%;
}


.notice-swipe-content {
  max-width: 90%;
  margin: 0;
  white-space: nowrap;
  /* 不换行 */
  overflow: hidden;
  /* 超出部分隐藏 */
  text-overflow: ellipsis;
  text-align: left;
}

::v-deep .van-notice-bar__left-icon,
.van-notice-bar__right-icon {
  min-width: 16px;
  font-size: 14px;
}

.van-notice-bar-box {

  background: #fffbe8;
}

::v-deep .van-swipe-item {
  display: flex;
  min-width: 8em;
}

::v-deep .van-notice-bar {
  height: 40px;
  max-width: 900px;
  margin: 0 auto;

}

@media (min-width: 992px) {

  .notice-swipe {
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    margin-left: 10px;
  }

  ::v-deep .van-notice-bar__left-icon,
  .van-notice-bar__right-icon {
    min-width: 16px;
    font-size: 14px;
  }

  ::v-deep .van-notice-bar {
    height: 40px;
    max-width: 900px;
    margin: 0 auto;

  }




  .container {
    height: 100%;
    padding-bottom: 75px;
    overflow-y: auto;

  }

  .main-page {
    max-width: 900px;
    margin: 0 auto;
  }

  .top-banner {
    .banner-video {
      width: 100%;
    }

    .banner-img img {
      width: 100%;
    }
  }

  .job-list {
    padding-bottom: 25px;

    .event-cover {
      display: block;
      width: 200px;
      padding-left: 20px;
      margin: 0;
    }


    ::v-deep .van-empty__image {
      width: 216px;
    }

    ::v-deep .van-empty__image img {
      width: 216px;
      height: 150px;
    }

    ::v-deep .van-empty__bottom {
      color: #999999;
      font-size: 14px;
    }
  }

  .company-info {
    padding: 15px 19px;

    .title {
      .name {
        margin: 0;
        font-size: 18px;
        padding-left: 8px;

        &::before {
          display: block;
          content: "";
          width: 3px;
          height: 18px;
          top: 4px;
          left: 0;
        }
      }

      span {
        line-height: 24px;
      }
    }
  }

  ::v-deep .van-popup__close-icon--top-right {
    top: 24px;
  }

  .btn {
    height: 36px;
    width: 100px;
    font-size: 16px;
    border-radius: 25px;
    border: 1px solid #eaedf0;
    cursor: pointer;
  }

  .confirm {
    color: #ffffff;
    margin-left: auto;
  }

  .job-result {
    .btn {
      margin: 0 auto;
      cursor: pointer;
    }
  }

  .detail-join {
    .btn {
      margin: 10px auto;
      cursor: pointer;
    }
  }

  ::v-deep {
    .notice-list {
      .van-cell__title {
        text-align: left;
        font-size: 14px !important;
        padding-left: 10px;
      }
    }
  }

  .my-sign {
    margin: 0 20px;
    display: flex;
    align-items: center;
    padding: 10px;
    flex-wrap: wrap;
    background: #f3f3f3;
    border-radius: 20px;
    font-size: 15px;
    position: relative;
    margin-bottom: 10px;

    p {
      width: 30%;
      margin: 0 5px;
      text-align: left;
      margin-bottom: 5px;
    }

    p:last-child {
      min-width: 100%;
    }

    .btn-box {
      display: flex;
      position: absolute;
      top: 5px;
      right: 5px;
    }

    .btn {
      width: 80px;
      height: 30px;
      margin: 0;
    }
  }

  .detail-join-fixed {
    width: 100%;
    background: #fff;
    position: fixed;
    bottom: 0px;
    left: 0px;
    text-align: center !important;
    z-index: 999;
    margin-bottom: 0 !important;
  }
}
</style>

<style scoped lang="scss">
.job-detail {
  padding-top: 15px;
}

.top-info {
  padding: 0 20px;
  align-self: flex-start;
  display: flex;
  justify-content: space-between;

  .title {
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    line-height: 33px;
    margin-right: 20px;
    text-align: left;
    padding-bottom: 0.5em;
  }

  .go-detail {
    width: 60px;
    min-width: 60px;
    margin-right: 30px;
    transform: translateY(1.5px);
    text-decoration: underline;
    color: #e95944;
    font-size: 12px;
    margin-top: 7px;
  }
}

.labels {
  margin: 0px 20px;
  padding-bottom: 10px;
  display: flex;
  height: 20px;
  align-items: center;

  .label {
    font-size: 14px;
    color: #666666;
    display: flex;
    align-items: center;

    .icon-label {
      width: 15px;
      height: 15px;
      margin-right: 6px;
    }

    span {
      height: 20px;
      line-height: 20px;
    }

    span.work-city {
      max-width: 75px;
    }
  }


  .event-label {
    display: block;
  }

  .bar {
    width: 1px;
    height: 15px;
    margin: 0 10px;
    background: #f1f4f6;
  }
}

.scroll-div {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .release-time {
    font-size: 12px;
    color: #999999;
    line-height: 17px;
    margin-bottom: 14px;
  }

  .card-info {
    padding: 6px 15px;
    background: #f8fafb;
    border-radius: 10px;
    margin-bottom: 25px;

    .van-col {
      padding: 5px;
      display: flex;
      align-items: center;
      font-size: 14px;

      .info-label {
        color: #999999;
      }

      .info-value {
        margin-left: 10px;
        font-weight: bold;
        color: #333333;
        max-width: 70px;
      }
    }
  }

  .sub-title {
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 0px;
    padding-top: 15px;
  }

  .detail {
    width: 100%;
    font-size: 15px;
    color: #666666;
    word-wrap: break-word;
    word-break: normal;
    white-space: pre-wrap;
    text-align: left;
    max-width: 100%;
    line-height: 28px;
    margin-bottom: 0px;

    p {
      margin: 0;
    }
  }
}

::v-deep .van-loading {
  width: 100%;
  height: 100%;
  background: white;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.popup {
  overflow: hidden;

  .scroll-div {
    height: 354px;
    max-height: 48vh;
    overflow-y: scroll;
  }
}

::v-deep .detail p {
  margin-top: 10px;
  margin-bottom: 0;
}

@media (min-width: 992px) {
  .job-detail {
    padding-top: 15px;
    border-bottom: 1px solid #efefef;
    display: flex;
    // align-items: center;
    // justify-content: space-between;
  }

  .top-info {
    padding: 0 20px;
    align-self: flex-start;
    display: flex;
    justify-content: space-between;

    .title {
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      line-height: 33px;
      margin-right: 20px;
      text-align: left;
    }

    .go-detail {
      width: 60px;
      min-width: 60px;
      margin-right: 30px;
      transform: translateY(1.5px);
      text-decoration: underline;
      color: #e95944;
      font-size: 12px;
      margin-top: 7px;
    }
  }

  .labels {
    margin: 0px 20px;
    padding-bottom: 10px;
    display: flex;
    height: 20px;
    align-items: center;
    position: absolute;
    bottom: 0;



    .label {
      font-size: 14px;
      color: #666666;
      display: flex;
      align-items: center;

      .icon-label {
        width: 15px;
        height: 15px;
        margin-right: 6px;
      }

      span {
        height: 20px;
        line-height: 20px;
      }

      span.work-city {
        max-width: 75px;
      }
    }

    .event-label {
      display: block;
    }

    .bar {
      width: 1px;
      height: 15px;
      margin: 0 10px;
      background: #f1f4f6;
    }
  }

  .scroll-div {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .release-time {
      font-size: 12px;
      color: #999999;
      line-height: 17px;
      margin-bottom: 14px;
    }

    .card-info {
      padding: 6px 15px;
      background: #f8fafb;
      border-radius: 10px;
      margin-bottom: 25px;

      .van-col {
        padding: 5px;
        display: flex;
        align-items: center;
        font-size: 14px;

        .info-label {
          color: #999999;
        }

        .info-value {
          margin-left: 10px;
          font-weight: bold;
          color: #333333;
          max-width: 70px;
        }
      }
    }

    .sub-title {
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      margin-bottom: 0px;
      padding-top: 15px;
    }

    .detail {
      font-size: 15px;
      color: #666666;
      word-wrap: break-word;
      word-break: normal;
      white-space: pre-wrap;
      text-align: left;
      max-width: 100%;
      line-height: 28px;
      margin-bottom: 0px;

      p {
        margin: 0;
      }
    }
  }

  ::v-deep .van-loading {
    width: 100%;
    height: 100%;
    background: white;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
  }

  .popup {
    overflow: hidden;

    .scroll-div {
      height: 354px;
      max-height: 48vh;
      overflow-y: scroll;
    }
  }

  ::v-deep .detail p {
    margin-top: 10px;
    margin-bottom: 0;
  }

  .btn-download {
    width: 120px;
    height: 40px;
    margin-top: 15px;
    color: #e95944;
    background: #ddd;
  }
}
</style>


<style>
.notice-list a {
  text-decoration: none !important;
}

.notice-list {
  text-align: left;
}
</style>